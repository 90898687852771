import React from 'react';
import Layout from "../../components/layout"
import ChooseService from "../../components/mainpage/ChooseService";

function Offers() {
    return (
        <>
            <Layout>
                <ChooseService />
            </Layout>
        </>
    );
}

export default Offers;