import React from 'react';
import '../../styles/components/_choose-service.scss'

function ChooseService(props) {
    const orderWays = [
        {
            type: 'standard',
            label: 'Save Time',
            title: 'Standard',
            description: 'The project manager will choose the most qualified writer for you.',
            bullets: [
                {
                    subtitle: 'Pricing is based on our <b> service calculator. </b>'
                },
                {
                    subtitle: 'Writer allocation is managed by our project manager.'
                },
                {
                    subtitle: 'You can request your <b>preferred writer</b>, even if they are offline.'
                }
            ],
            btn: 'Proceed',
            route_name: 'https://shop.customessaymeister.com/order/standard'
    
        },
        {
            type: 'express',
            label: 'Save Money',
            title: 'Express',
            description: 'You create a project in one click and pick the writer yourself.',
            bullets: [
                {
                    subtitle: 'You are in <b>total control</b> and work with the writer directly.'
                },
                {
                    subtitle: 'You get <b>different price offers</b> from different writers.'
                },
                {
                    subtitle: 'You pick the <b>best offer</b>, based on the reviews.'
                }
            ],
            btn: 'Proceed',
            route_name: 'https://shop.customessaymeister.com/order/express'
        }
    ]

    return (
        <div className='order_way'>
            <h2 className="order_way__title">
                Choose service
            </h2>
            <p className="order_way__desc">
                There are 2 ways to order. The Standard option is fully managed by our project managers, who will assign the best possible writer
                 or your preferred writer, if selected. The Express option is the marketplace model, where you pick the best writer yourself.
            </p>
            <div className="order_way_list">
                {orderWays.map((way, index) => (
                    <div
                        key={index}
                        className="order_way_list__item"
                    >
                        <span
                            className={`order_way_list__item-label order_way_list__item-label--${way.type}`}
                        >
                            { way.label }
                        </span>
                        <h1 className="order_way_list__item-title">
                            { way.title }
                        </h1>
                        <div className="order_way_list__item-body">
                            <p className="order_way_list__item-description">
                                { way.description }
                            </p>
                            {way.bullets.map((bullet, bulletIndex) => (
                                <div
                                    key={bulletIndex}
                                    className="order_way_list__item-subtitle-wrapper"
                                >
                                    <div className="order_way_list__item-subtitle-wrapper-icon">
                                        <img src={require('../../images/checkOnly.svg').default} alt='check'/>
                                    </div>
                                    <p
                                        className="order_way_list__item-description"
                                        dangerouslySetInnerHTML={{ __html: bullet.subtitle}}
                                    />
                                </div>
                            ))}
                            
                        </div>
                        <a
                            href={way.route_name}
                            className="order_way_list__item-btn"
                        >
                            { way.btn }
                        </a>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default ChooseService;